import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { AnchorLink } from "gatsby-plugin-anchor-links"

const HeroHair = () => {
  const {t} = useTranslation();

  return (
    <div id="hero" className="min-h-screen text-center text-white bg-center bg-no-repeat bg-cover rounded-br-profileHero" style={{ 
      backgroundImage: `url("https://s3.eu-west-3.amazonaws.com/100hawks.com/hair.jpeg")` 
    }}>
      <div className='max-w-md px-2 mx-auto mb-12 pt-44 md:px-0'>
        <h1 className='text-left text-9xl drop-shadow-md'>the</h1>
        <h1 className=' text-9xl drop-shadow-md'>profile</h1>
        <h1 className='text-right text-9xl drop-shadow-md'>clinic</h1>
      </div>
      <div className='max-w-md px-2 mx-auto md:px-0'>
        <h2 className='mb-24 text-2xl uppercase'>{t('capilar_subtitle')}</h2>
        <AnchorLink to="#contact" title="Book">
          <button className='px-16 py-4 mb-8 text-lg font-bold text-white uppercase rounded-full md:px-32 hover:bg-gray-600 font-Montserrat bg-buttonColor-600 drop-shadow-profile'>{t('hero_cta')}</button>
        </AnchorLink>
        <p className='w-1/2 pb-48 mx-auto text-sm uppercase font-NewYork opacity-70'>{t('hero_free')}</p>
      </div>
    </div>
  );
};

export default HeroHair;