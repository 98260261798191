import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { StaticImage } from "gatsby-plugin-image"

const ServicesHair = () => {
  const {t} = useTranslation();

  return (
    <>
      <div id="services" className="py-16 bg-bgColor-600 md:py-16">
        <div className="container grid grid-cols-1 px-16 mx-auto space-x-8 lg:grid-cols-2">
          <div className='pb-16 mx-auto lg:pb-0'>
            <h2 className='max-w-xs pb-4 text-5xl font-bold font-NewYork'>{t('hair_services_title')}</h2>
            <p className='max-w-lg mb-8 text-base leading-10'>{t('hair_services_subtitle')}</p>
            <StaticImage src="../../images/sampayo.png" alt="Doctor David Sampayo" />
          </div>
          <div className='max-w-lg mx-auto'>
            <h2 className='mb-6 text-base font-bold leading-10 font-NewYork opacity-70'>{t('services_list_title')}</h2>
            <div>
              <div className='mb-4 border-b border-black border-opacity-20'>
                <h3 className='text-2xl font-bold leading-10 font-NewYork'>{t('capilar_services_definition_title')}</h3>
                <p className='pb-4 text-base leading-10 font-Montserrat'>{t('capilar_services_definition_text')}</p>
              </div>
              <div className='mb-4 border-b border-black border-opacity-20'>
                <h3 className='text-2xl font-bold leading-10 font-NewYork'>{t('capilar_services_estetica_title')}</h3>
                <p className='pb-4 text-base leading-10 font-Montserrat'>{t('capilar_services_estetica_text')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesHair;